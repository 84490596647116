import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { isMobile } from "react-device-detect";

// Import Styles and Image
import TakeSurveyStyles from "../TakeSurveyStyles";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../config/constants";

function Matrix(props) {
  // Get Props Values
  const { classes, langCode, item, surveyQuesAnswer, errorStatus, questionIndex, questionPanelHeight } = props;

  const [selectedValue, setSelectedValue] = useState(surveyQuesAnswer ? surveyQuesAnswer[langCode] : []);

  const { ansSet, text, subQues, isRequired } = item;
  const questionText = text[langCode];
  const ansSetArr = ansSet[langCode];
  const answerSet = Object.values(ansSetArr);

  const handleChange = (event) => {
    const question = event.target.name;
    const answer = event.target.value;

    const ansObj = { [question]: answer };
    if (selectedValue && selectedValue.hasOwnProperty(question)) {
      delete selectedValue[question];
    }
    const matrixAnswers = { ...ansObj, ...selectedValue };
    setSelectedValue(matrixAnswers);
    props.handleAnswerUpdate(matrixAnswers);
  };

  //Remove answer when unChecked
  const handleOnClick = (e) => {
    const curQus = e.target.name;
    const curAns = e.target.value;

    if (selectedValue && selectedValue[curQus] && selectedValue[curQus] == curAns) {
      delete selectedValue[curQus];
      setSelectedValue(selectedValue);
      props.handleAnswerUpdate(selectedValue);
    }
  };

  return (
    <React.Fragment>
      {/* Matrix */}
      <Grid container className="mt-2 mb-2">
        <Grid item xs={12} className="pb-1">
          <TypographyComponent variant="body1" extraProps={{ tabIndex: "0" }}>
            {questionIndex}. {questionText} {isRequired ? "*" : ""}
          </TypographyComponent>
          {errorStatus ? <ErrMsgComponent /> : null}
        </Grid>

        <TableContainer className={classes.matrixTable}>
          <Table className={classes.table} size="small" aria-label="simple table">
            <TableHead className={classes.matricTabHeader}>
              <TableRow>
                <TableCell className="matrixAbsCol" component="th" scope="row"></TableCell>
                {answerSet.map((ans, anskey) => (
                  <TableCell align="center" key={anskey} className={`fontSize12`}>
                    {ans}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subQues.map((ques, qkey) => {
                let selectedAns = selectedValue && selectedValue.hasOwnProperty(ques.id) ? selectedValue[ques.id] : null;
                return (
                  <TableRow className="singleQuestionMatrix" key={qkey}>
                    <TableCell component="th" scope="row" className="fontSize12 pl-1 matrixQuesCol" tabIndex={0}>
                      {ques.text[langCode]}
                    </TableCell>

                    {answerSet.map((ans, anskey) => {
                      const ansStatus = String(selectedAns) && String(selectedAns) == Object.keys(ansSetArr)[anskey] ? true : false;
                      return (
                        <TableCell align="center" key={anskey} style={{outline:0}}>
                          <FormControlLabel
                            checked={ansStatus}
                            onChange={handleChange}
                            onClick={handleOnClick}
                            value={String(Object.keys(ansSetArr)[anskey])}
                            name={ques.id}
                            style={{outline: 0}}
                            id={`${ques.id}_${anskey}`}
                            className={classes.subQuestionChoicesRadio}
                            control={<Radio className={classes.subQuestionChoicesRadio}/>}
                            tabIndex={0}
                          />
                          {/* <Radio
                            checked={ansStatus}
                            onChange={handleChange}
                            onClick={handleOnClick}
                            value={String(Object.keys(ansSetArr)[anskey])}
                            name={ques.id}
                            id={`${ques.id}_${anskey}`}
                            inputProps={{
                              "aria-label": `${ques.id}_${anskey}`,
                              "tabindex": 0,
                            }}
                            className={classes.subQuestionChoicesRadio}
                          />
                          */}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
}
// default props
Matrix.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => {},
  errorStatus: false,
};

// prop types
Matrix.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  errorStatus: PropTypes.bool,
};
export default withStyles(TakeSurveyStyles)(Matrix);
